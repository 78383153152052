import { Component, Input, Provider, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

const COUNTRY_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AppCheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  providers: [COUNTRY_CONTROL_VALUE_ACCESSOR],
  templateUrl: './app-checkbox.component.html',
  styleUrls: ['./app-checkbox.component.scss']
})
export class AppCheckboxComponent<T> extends ControlValueAccessorDirective<T> {
  @Input() id = 'checkboxId';
  @Input() label = 'Label';

  labelColor = 'white';

  style = {
    '--primary-color': localStorage.getItem('primaryColor') || '#fff',
  };
}