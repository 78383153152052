import { Component, EventEmitter, Input, input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { AppButtonComponent } from "../app-button/app-button.component";
import { WaveService } from 'src/app/core/services/wave.service';

declare var bootstrap: any;

@Component({
  selector: 'app-modal-change-checkin',
  standalone: true,
  imports: [AppButtonComponent],
  templateUrl: './app-modal-change-checkin.component.html',
  styleUrl: './app-modal-change-checkin.component.scss'
})
export class AppModalChangeCheckinComponent {

   modalId = input.required<string>();
   modalTitle = input<string>('Atenção!');
   modalInstance: any;

   @Input() pendentTicket: any;

   confirmBtn = {
    buttonText: 'Prosseguir para o pagamento',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
  };

  cancelBtn = {
    buttonText: 'Cancelar ingresso pendente',
    borderRadius: '25px',
    buttonSize: 'btn btn-sm px-5 py-2 btn-secondary',
  };


   private modalSubscription: Subscription | undefined;

      @Output() onModalGuestConfirm = new EventEmitter();

      constructor(private modalService: ModalService, private alertService: AlertService, private waveService: WaveService) { }

      ngOnInit(): void {



        this.modalSubscription = this.modalService.modalState$.subscribe((state) => {
          if(state.id === 'modalChangeCheckin'){
          const modalElement = document.getElementById(state.id);
          if (modalElement) {
            if (state.action === 'open') {
              this.modalInstance = new bootstrap.Modal(modalElement); // Instanciando o modal
              this.modalInstance.show();
              modalElement.addEventListener('hidden.bs.modal', () => {
              });
            } else if (state.action === 'close') {
              if (this.modalInstance) {
                this.modalInstance.hide();
              }
            }
          }
        }

        });

      }

      closeModal() {
        if (this.modalInstance) {
          this.modalInstance.hide();
        }
      }




      onCancel() {
        this.waveService.cancelByOrderId(this.pendentTicket?.orderId).subscribe({
              next: (res: any) => {
              this.closeModal();
              this.alertService.showAlert(AlertType.SUCCESS, 'Checkin cancelado com sucesso.');
              window.location.reload();
              }, error: (error) => {
                console.error('Error loading data:', error);
              }
            })

        this.modalInstance.hide();
      }

}
