<div
  class="modal fade"
  id="{{ modalId() }}"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ modalTitle() }}
        </h1>
        <button type="button" class="btn-close" (click)="closeModal()"></button>
      </div>
      <div class="modal-body">
        <span>
          Existe um pedido pendente de pagamento, realize o cancelamento desse ingresso pendente para continuar!
        </span>
        <br />
        <div class="modal-footer mt-1 justify-content-center">
          <app-button
            class="mx-2 my-1"
            id="confirmModalBtn"
            [buttonOptions]="cancelBtn"
            (buttonClick)="onCancel()"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
