import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppButtonComponent } from 'src/app/shared/components/app-button/app-button.component';
import { AppInputComponent } from 'src/app/shared/components/app-input/app-input.component';
import { AppFormModalComponent } from 'src/app/shared/components/app-form-modal/app-form-modal.component';
import { AppTicketCardComponent } from 'src/app/shared/components/app-ticket-card/app-ticket-card.component';

import { ModalService } from 'src/app/core/services/modal.service';
import { SelectModel } from 'src/app/shared/components/app-select-input/app-select-input.component';
import { FormControl } from '@angular/forms';
import { TicketModelTO } from 'src/app/shared/models/ticketToModel';
import { MemberService } from 'src/app/core/services/member.service';
import { UserModel } from 'src/app/shared/models/userModel';
import { UserService } from 'src/app/core/services/user.service';
import { WaveService } from 'src/app/core/services/wave.service';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';
import { Router } from '@angular/router';
import { GuestService } from 'src/app/core/services/guest.service';
import { SubstituteService } from 'src/app/core/services/substitute.service';
import { AppConfirmModalComponent } from "../../shared/components/app-confirm-modal/app-confirm-modal.component";
import { AppModalChangeCheckinComponent } from "../../shared/components/app-modal-change-checkin/app-modal-change-checkin.component";

@Component({
  selector: 'app-check-in',
  standalone: true,
  imports: [CommonModule, AppButtonComponent, AppInputComponent, AppTicketCardComponent, AppFormModalComponent, AppConfirmModalComponent, AppModalChangeCheckinComponent],
  templateUrl: './check-in.component.html',
  styleUrl: './check-in.component.scss'
})
export class CheckInComponent implements OnInit {
  labelColor = 'white';
  user: UserModel;
  memberData: any;
  memberPlan: any;
  memberDependents: any = [];
  selectInputOptionsModal: SelectModel[] = [];
  checkinSelected: any;
  checkinsHistory: any = [];
  linkModalConfirm: string = '';
  limitCheckin: any;
  pendentTicket:any;
  showDiv = false;
  guestSelect: any;
  substituteSelect: any;


  ingressosCheckIn: any[] = [];

  buttonBackOptions = {
    buttonText: 'Voltar',
    buttonSize: 'btn btn-md px-3 btn-secondary',
    borderRadius: '25px',
  };

  constructor(
    private modalService: ModalService,
    private userService: UserService,
    private waveService: WaveService,
    private guestService: GuestService,
    private substituteService: SubstituteService,
    private router: Router,
    private alertService: AlertService,
    private memberService: MemberService
  ) { this.user = this.userService.currentUserValue as UserModel; }

  ngOnInit(): void {

    this.validateAccess();

    this.memberService.getMemberDashboardData(this.user.id).subscribe(res => {
      this.memberData = res;
      let option: SelectModel = {
        code: res.id,
        name: `${res.name} (Sócio)`
      }
      this.selectInputOptionsModal.push(option);
      if (this.memberData) {
        this.memberService.getMemberDependentesByMember(this.memberData.id).subscribe(data => {
          this.memberDependents = data;
          data.content.forEach(dep => {
            let optionDep: SelectModel = {
              code: dep.id,
              name: `${dep.name} (Dependente)`
            }
            this.selectInputOptionsModal.push(optionDep);
          })
        })
      }
    })
    this.memberService.getMembePlanDashboardData(this.user.id).subscribe(res => {
      this.memberPlan = res;
      if (this.memberPlan) {
        this.waveService.getNextActivesWaves(this.memberPlan.plan.id).subscribe(data => {
          this.ingressosCheckIn = data;
          this.showDiv = true;
        })
      }
    })
  }

  ingressosUsados = [
    {
      data: '06/04/2024',
      hora: '17:00',
      serie: 'BRASILEIRÃO SÉRIE A',
      arena: 'Arena BRB Mané Garrincha',
      bilhete: {
        socio: 'PREMIUM',
        arquibancada: 'CHECK-IN'
      },
      teams: [
        {
          logo: '/assets/teams/football-fc.png',
          nome: 'Football FC'
        },
        {
          logo: '/assets/teams/estd.png',
          nome: 'ESTD'
        }
      ]
    },
    {
      data: '01/05/2023',
      hora: '16:00',
      serie: 'BRASILEIRÃO SÉRIE A',
      arena: 'Arena BRB Mané Garrincha',
      bilhete: {
        socio: 'PREMIUM',
        arquibancada: 'CHECK-IN'
      },
      teams: [
        {
          logo: '/assets/teams/football-fc.png',
          nome: 'Football FC'
        },
        {
          logo: '/assets/teams/estd.png',
          nome: 'ESTD'
        }
      ]
    },
    {
      data: '13/06/2022',
      hora: '09:00',
      serie: 'BRASILEIRÃO SÉRIE A',
      arena: 'Arena BRB Mané Garrincha',
      bilhete: {
        socio: 'PREMIUM',
        arquibancada: 'CHECK-IN'
      },
      teams: [
        {
          logo: '/assets/teams/football-fc.png',
          nome: 'Football FC'
        },
        {
          logo: '/assets/teams/estd.png',
          nome: 'ESTD'
        }
      ]
    },
    {
      data: '13/06/2022',
      hora: '09:00',
      serie: 'BRASILEIRÃO SÉRIE A',
      arena: 'Arena BRB Mané Garrincha',
      bilhete: {
        socio: 'PREMIUM',
        arquibancada: 'CHECK-IN'
      },
      teams: [
        {
          logo: '/assets/teams/football-fc.png',
          nome: 'Football FC'
        },
        {
          logo: '/assets/teams/estd.png',
          nome: 'ESTD'
        }
      ]
    },
    {
      data: '13/06/2022',
      hora: '09:00',
      serie: 'BRASILEIRÃO SÉRIE A',
      arena: 'Arena BRB Mané Garrincha',
      bilhete: {
        socio: 'PREMIUM',
        arquibancada: 'CHECK-IN'
      },
      teams: [
        {
          logo: '/assets/teams/football-fc.png',
          nome: 'Football FC'
        },
        {
          logo: '/assets/teams/estd.png',
          nome: 'ESTD'
        }
      ]
    },
    {
      data: '13/06/2022',
      hora: '09:00',
      serie: 'BRASILEIRÃO SÉRIE A',
      arena: 'Arena BRB Mané Garrincha',
      bilhete: {
        socio: 'PREMIUM',
        arquibancada: 'CHECK-IN'
      },
      teams: [
        {
          logo: '/assets/teams/football-fc.png',
          nome: 'Football FC'
        },
        {
          logo: '/assets/teams/estd.png',
          nome: 'ESTD'
        }
      ]
    }
  ] as TicketModelTO[];

  ingressosNaoUsados = [
    {
      data: '06/04/2024',
      hora: '17:00',
      serie: 'BRASILEIRÃO SÉRIE A',
      arena: 'Arena BRB Mané Garrincha',
      bilhete: {
        socio: 'PREMIUM',
        arquibancada: 'CHECK-IN'
      },
      teams: [
        {
          logo: '/assets/teams/football-fc.png',
          nome: 'Football FC'
        },
        {
          logo: '/assets/teams/estd.png',
          nome: 'ESTD'
        }
      ]
    }
  ] as TicketModelTO[];

  modalBodyMessage = 'Deseja realizar o check-in?';
  modalId = 'modalCheckinTicket';
  selectControlModal: FormControl = new FormControl();


  async openCheckInModal(event: any): Promise<void> {
    try {
      this.checkinsHistory = await this.waveService.getCheckinsRealizados(event, this.memberPlan.id).toPromise();
      this.limitCheckin = await this.waveService.getLimitByMemberPlan(this.memberPlan.id).toPromise();

    } catch (error) {
      console.error('Error:', error);
    }
    this.checkinSelected = event;
    this.modalService.open(this.modalId);
  }


  async onModalConfirm(event: any): Promise<void> {
    const waveSelect = this.ingressosCheckIn.find((i: any) => i.id === this.checkinSelected);
    let dependentSelect: any;
    if (event.isDependent) {
      dependentSelect = this.memberDependents.content.find((i: any) => i.id === event.clientSelected)
    }

    if (event.isGuest && event.guestData) {
      let guestPayload: any = {
        id: event.guestData.idControl ? event.guestData.idControl : '',
        name: event.guestData.nameControl,
        cpf: event.guestData.cpfControl,
        email: event.guestData.emailControl
      }


      try {
        this.guestSelect = await this.guestService.createGuestCheckin(guestPayload).toPromise();
      } catch (error) {
        console.error('Error:', error);
      }
    }

    if (event.isSubstitute && event.substituteData) {
      let substitutePayload: any = {
        id: event.substituteData.idControl ? event.substituteData.idControl : '',
        name: event.substituteData.nameControl,
        cpf: event.substituteData.cpfControl,
        email: event.substituteData.emailControl
      }


      try {
        this.substituteSelect = await this.substituteService.createSubstituteCheckin(substitutePayload).toPromise();
      } catch (error) {
        console.error('Error:', error);
      }
    }

    let ticketPayload: any = {
      customer: {
        name: event.isDependent ? dependentSelect?.name : event.isGuest ? event.guestData.nameControl : event.isSubstitute ? event.substituteData.nameControl : this.memberData?.name,
        doc: event.isDependent ? dependentSelect?.document : event.isGuest ? event.guestData.cpfControl : event.isSubstitute ? event.substituteData.cpfControl : this.memberData?.cpf,
        email: event.isDependent ? dependentSelect?.email : event.isGuest ? event.guestData.emailControl : event.isSubstitute ? event.substituteData.emailControl : this.memberData?.emails[0]?.email,
        mifare: event.isDependent ? dependentSelect?.membershipCard?.cardNumber ? dependentSelect?.membershipCard?.cardNumber : '' : `1${this.memberData.cpf}`,
        phones: [this.memberData.phones[0].number]
      },
      tickets: [
        {
          ticketId: event.ticketSelected,
          ticketValue: event.isDependent ? waveSelect.waveTicketsDependent.find((i: any) => i.ticketId === event.ticketSelected).ticketValue : event.isGuest ? waveSelect.waveTicketsGuest.find((i: any) => i.ticketId === event.ticketSelected).ticketValue : waveSelect.waveTicketsSocio.find((i: any) => i.ticketId === event.ticketSelected).ticketValue,
          customer: {
            name: event.isDependent ? dependentSelect?.name : event.isGuest ? event.guestData.nameControl : event.isSubstitute ? event.substituteData.nameControl : this.memberData?.name,
            doc: event.isDependent ? dependentSelect?.document : event.isGuest ? event.guestData.cpfControl : event.isSubstitute ? event.substituteData.cpfControl : this.memberData?.cpf,
            email: event.isDependent ? dependentSelect?.email : event.isGuest ? event.guestData.emailControl : event.isSubstitute ? event.substituteData.emailControl : this.memberData?.emails[0]?.email,
            mifare: event.isDependent ? dependentSelect?.membershipCard?.cardNumber ? dependentSelect?.membershipCard?.cardNumber : '' : `1${this.memberData.cpf}`,
            phones: [this.memberData.phones[0].number]
          },
        }
      ]
    }




    this.waveService.realizarCheckin(ticketPayload).subscribe({
      next: (res: any) => {

        if (res?.mandatoryFace && event?.isGuest) {
          this.linkModalConfirm = res?.link;
          this.modalService.open("modalConfirm");
        }

        if(res.orderStatus && res?.orderStatus === "Initial"){
          this.pendentTicket = res;
          this.modalService.open("modalChangeCheckin");
        }

        else if (res?.mandatoryFace) {
          window.open(res?.link, '_blank');
        } else {
          let checkinPayload: any = {
            waveId: this.checkinSelected,
            ticketId: event.isDependent ? waveSelect.waveTicketsDependent.find((i: any) => i.ticketId === event.ticketSelected).id : event.isGuest ? waveSelect.waveTicketsGuest.find((i: any) => i.ticketId === event.ticketSelected).id : waveSelect.waveTicketsSocio.find((i: any) => i.ticketId === event.ticketSelected).id,
            memberPlanId: this.memberPlan.id,
            memberDependentId: event.isDependent ? event.clientSelected : '',
            guestCheckinId: event.isGuest ? this.guestSelect?.id : '',
            substituteCheckinId: event.isSubstitute ? this.substituteSelect?.id : '',
            ticketLink: res?.link || '',
            ticketValue: event.isDependent ? waveSelect.waveTicketsDependent.find((i: any) => i.ticketId === event.ticketSelected).ticketValue : event.isGuest ? waveSelect.waveTicketsGuest.find((i: any) => i.ticketId === event.ticketSelected).ticketValue : waveSelect.waveTicketsSocio.find((i: any) => i.ticketId === event.ticketSelected).ticketValue,
            ticketCode: res?.codes[0] || '',
            orderId: res.orderId
          }
          this.waveService.criarCheckin(checkinPayload).subscribe(data => {
            this.alertService.showAlert(AlertType.SUCCESS, 'Checkin realizado com sucesso.');
            window.open(res.link, '_blank');
            window.location.reload();
          });
        }
      },
      error: (error) => {
        console.error('Error: ', error);
      }
    })
  }


  // Método de validação de acesso
  private validateAccess(): void {
    const activeSections = localStorage.getItem('activeSections');

    if (!activeSections) {
      this.handleAccessDenied();
      return;
    }

    const parsedSections = JSON.parse(activeSections);

    if (!parsedSections['ingressos']) {
      this.handleAccessDenied();
    }
  }

  // Tratamento de acesso negado
  private handleAccessDenied(): void {
    this.alertService.showAlert(AlertType.WARNING, 'Você não possui acesso a essa página');
    this.router.navigate(['/dashboard']);
  }

  back(): void {
    window.history.back();
  }

}
