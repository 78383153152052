import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreditCardInfoModel } from 'src/app/shared/models/paymentData';
import { BaseService } from './base/base.service';
import { ErrorHandlerService } from './error-handler.service';
import { environment } from 'src/environments/environment';
import { OperationType } from 'src/app/features/finalizar-pagamento/finalizar-pagamento.component';

@Injectable({
  providedIn: 'root'
})
export class PaymentDataService extends BaseService {

  private env = environment;
  operationType: OperationType;

  constructor(
    http: HttpClient,
    errorHandlerService: ErrorHandlerService) {
    super(http, errorHandlerService);
  }

  getCreditCardInfo(): Observable<CreditCardInfoModel[]> {
    return this.get<CreditCardInfoModel[]>('assets/mock/creditCardInfoMockData.json');
  }


  sendPaymentData(paymentData: PaymentDataPayload): Observable<PaymentDataResponse> {

    return this.post<PaymentDataResponse>(`${this.env.publicApiSocioUrl}v1/socio/charger`, paymentData);
  }

  sendRecurrentUpgrade(paymentData: RecurrentUpgradeTO): Observable<RecurrentUpgradeTO> {
    return this.http.post<RecurrentUpgradeTO>(`${this.env.socioApiUrl}/member-plans/upgrade-plan`, paymentData);
  }
  

  getPaymentOrder(paymentId: string): Observable<PaymentDataResponse> {
    let params = new HttpParams();
    params = params.append('showLoader', 'false');
    return this.get<PaymentDataResponse>(`${this.env.publicApiSocioUrl}v1/socio/orders/${paymentId}`,{params});
  }

  setOperationType(operationType: OperationType) {
    this.operationType = operationType;
  }

  getOperationType(): OperationType {
    return this.operationType;
  }

}

export interface PaymentDataPayload {
  cardNumber: string;
  cardHolderName: string;
  cardCvv: string;
  cardExpirationDate: string;
  installments: number;
  amount?: number;
  planId: string;
  memberId: string;
  paymentMethodId: string;
  planPaymentId: string;
  periodicityId: string;
  operationType: string;
  cpf?:string
  canUseCard: boolean;
}

export interface FreePaymentDataPayload {
  
    memberId: string,
    planId: string,
    planPaymentId: string,
    methodId: string
  
}

export interface RecurrentUpgradeTO {
 memberId: string;
  planId: string;
  planPaymentId: string;
  methodId: string;
  amount: number;
  recurrence: boolean;
  status?: string;
}

export interface PaymentDataResponse {
  id: string;
  status: string;
  qrCodeData: string;
  qrCodeImageUrl: string;
  expiresIn: number;
  error: string;
  declinedCode: string;
  code: number;
  message: string;
}